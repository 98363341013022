/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.loading {
  /*  --background: #222;
  --spinner-color: rgb(255, 0, 0);
  color: rgb(255, 0, 0); */
}




.alert-radio-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
   white-space: pre-line !important;
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{

    -webkit-box-shadow: 0 0 0 30px rgb(242, 252, 194) inset !important;

    /*  margin: 5px;
    padding: 5px; */
    border-radius: 8px;


}

/*Change text in autofill textbox*/
input:-webkit-autofill{
   -webkit-text-fill-color: rgb(0, 0, 0) !important;
   margin-left: 5px;;
}

.no-padding{
  padding: 0px;
}
.main-grid{
  padding-left: 0px;padding-top: 5px;padding-right: 0px;padding-bottom: 0px;
}
.alert-padding{
  padding-left: 8px;
  padding-right: 8px;
}

.logo{
  max-width: 100px;
  width: 100px;
  text-align: center;
  margin-top: 0%;
}

#margin2{
  margin-left: 2px;margin-right: 2px;margin-top: 2px;margin-bottom: 2px;
}
#pad0{
  padding: 0px;
}
/* ion-card{
  margin-left: 2px;margin-right: 2px;margin-top: 2px;margin-bottom: 2px;
}
ion-col{
  padding: 0px;
} */
/*
.ios {
  ion-header {
    ion-title {
      padding-inline-start: 0;
      padding-inline-end: 0;
      text-align: center;

    }
  }
}
 */

 ion-title{
  padding-left: 0px;
  text-align: left;
  margin-left: 10px;
  margin-right: 0px;
}


.ios ion-title{
  margin-left: 80px;
}

.logo{
  margin-left: 10px;
}
